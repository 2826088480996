<template>
  <div>
    <div class="work-details">
      <div v-show="!sync" class="text-center">Loading</div>
      <div v-show="sync">
        <div class="row justify-content-end">
          <div class="col-md-12 text-center" v-show="!project.title">
            <h2 class="m-4">Sorry the project you are looking for doesn't exist</h2>
            <h4 class="text-white">
              <router-link to="/projects">Back to project</router-link>
            </h4>
          </div>
          <div class="col-md-10" v-show="project.title">
            <div class="work__details_info" data-aos="fade-up">
              <h4 class="work__title">{{project.title}}</h4>
            </div>
            <!-- /work details info -->
            <div class="work__details_text" data-aos="fade-up" data-aos-delay="200">
              <carousel
                :per-page="1"
                :autoplay="true"
                :autoplayTimeout="6000"
                :navigationEnabled="true"
              >
                <slide v-for="photo in project.images" :key="photo.id">
                  <img
                    class="img-fluid"
                    v-lazy="`${appConfig.api.projects_image_path}/${photo.title}`"
                    :alt="project.title"
                  />
                </slide>
              </carousel>
              <br />
              <p v-html="project.content"></p>
            </div>
            <!-- /work details text -->

            <div class="col-md-10">
              <div class="work__details_info_cards info__cards_three">
                <div class="row justify-content-center align-items-center">
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                    <div class="card__item">
                      <h6>Tools</h6>
                      <p>{{project.tools}}</p>
                    </div>
                  </div>
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
                    <div class="card__item">
                      <h6>Link</h6>
                      <p v-if="!project.link">Link not available</p>
                      <p v-else>
                        <a
                          target="_blank"
                          :href="project.link"
                          class="theme-font-color"
                        >Link to project</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <next-page path="/projects" title="Projects" content="Go Back To Projects"></next-page>
            <!-- /work details info cards -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ShowProject",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: "description", content: this.title },
        { name: "og:title", content: this.title },
      ],
    };
  },
  data() {
    return {};
  },
  computed: {
    project() {
      return this.$store.getters.getProject(this.$route.params.slug);
    },
    title() {
      let page = this.project.title ? this.project.title : "Projects";
      return `${page} - ${this.appConfig.name}`;
    },
    ...mapState(["sync", "appConfig"]),
  },
};
</script>

<style>
</style>
